import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Tanstack Query を活用したフロントエンドアーキテクチャの紹介 ~ 持続可能な開発を目指して ~",
  "date": "2023-03-31T10:40:59.000Z",
  "slug": "entry/2023/03/31/194059",
  "tags": [],
  "hero": "./2023_03_31.png",
  "heroAlt": "Tanstack Query を活用したフロントエンドアーキテクチャの紹介"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。医療プラットフォーム第一本部プロダクト開発室所属エンジニアの髙橋です。
普段の業務では、`}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/"
      }}>{`医療 SaaS プラットフォーム CLINICS`}</a>{` の医療機関向けアプリケーション（以下、CLINICS）の開発を担当しています。`}</p>
    <p>{`CLINICS では、昨年 10 月頃から React コードベースのリアーキテクチャに取り組んでいます。
その取り組みの 1 つとして、非同期状態管理に関連する実装を Tanstack Query を使って刷新しています。`}</p>
    <p>{`この記事では、CLINICS における Tanstack Query の活用方法を導入背景と狙いを含めて紹介します。`}</p>
    <p><strong parentName="p">{`目次`}</strong></p>
    {
      /* START doctoc generated TOC please keep comment here to allow auto update */
    }
    {
      /* DON'T EDIT THIS SECTION, INSTEAD RE-RUN doctoc TO UPDATE */
    }
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#tanstack-query-%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6"
        }}>{`Tanstack Query について`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#tanstack-query-%E3%82%92%E6%B4%BB%E7%94%A8%E3%81%97%E3%81%9F%E3%83%95%E3%83%AD%E3%83%B3%E3%83%88%E3%82%A8%E3%83%B3%E3%83%89%E3%82%A2%E3%83%BC%E3%82%AD%E3%83%86%E3%82%AF%E3%83%81%E3%83%A3"
        }}>{`Tanstack Query を活用したフロントエンドアーキテクチャ`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#resource-operation-%E3%81%AE%E5%AE%9F%E8%A3%85"
            }}>{`Resource Operation の実装`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#%E3%83%87%E3%82%A3%E3%83%AC%E3%82%AF%E3%83%88%E3%83%AA%E6%A7%8B%E6%88%90"
                }}>{`ディレクトリ構成`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#cachets"
                }}>{`cache.ts`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#queriests"
                }}>{`queries.ts`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#mutationsts"
                }}>{`mutations.ts`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#viewmodel-%E3%81%AE%E5%AE%9F%E8%A3%85"
            }}>{`ViewModel の実装`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#%E3%83%87%E3%82%A3%E3%83%AC%E3%82%AF%E3%83%88%E3%83%AA%E6%A7%8B%E6%88%90-1"
                }}>{`ディレクトリ構成`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#%E3%82%B5%E3%83%BC%E3%83%90%E3%82%B9%E3%83%86%E3%83%BC%E3%83%88"
                }}>{`サーバステート`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#%E3%83%95%E3%83%AD%E3%83%B3%E3%83%88%E3%82%A8%E3%83%B3%E3%83%89%E3%81%AB%E9%96%89%E3%81%98%E3%81%9F%E3%82%B9%E3%82%AD%E3%83%BC%E3%83%9E"
                }}>{`フロントエンドに閉じたスキーマ`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#%E3%83%95%E3%83%AD%E3%83%B3%E3%83%88%E3%82%A8%E3%83%B3%E3%83%89%E3%81%AB%E9%96%89%E3%81%98%E3%81%9F%E5%9E%8B"
                }}>{`フロントエンドに閉じた型`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#tanstack-query-%E5%B0%8E%E5%85%A5%E3%81%AE%E8%83%8C%E6%99%AF%E3%81%A8%E3%82%A2%E3%83%BC%E3%82%AD%E3%83%86%E3%82%AF%E3%83%81%E3%83%A3%E3%81%AE%E7%8B%99%E3%81%84"
        }}>{`Tanstack Query 導入の背景とアーキテクチャの狙い`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#%E8%83%8C%E6%99%AF%E3%82%B3%E3%83%BC%E3%83%89%E5%93%81%E8%B3%AA%E3%81%AE%E8%AA%B2%E9%A1%8C"
            }}>{`背景：コード品質の課題`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#%E7%8B%99%E3%81%84-1tanstack-query-%E3%81%AE%E5%B0%8E%E5%85%A5%E3%81%AB%E3%82%88%E3%82%8B%E9%96%8B%E7%99%BA%E4%BD%93%E9%A8%93%E3%81%AE%E5%90%91%E4%B8%8A"
            }}>{`狙い 1：Tanstack Query の導入による開発体験の向上`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#%E7%8B%99%E3%81%84-2%E3%82%B7%E3%83%B3%E3%83%97%E3%83%AB%E3%81%AA%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC%E5%88%86%E5%89%B2%E3%81%AB%E3%82%88%E3%82%8B%E5%AD%A6%E7%BF%92%E5%AE%B9%E6%98%93%E6%80%A7%E3%81%AE%E5%90%91%E4%B8%8A"
            }}>{`狙い 2：シンプルなレイヤー分割による学習容易性の向上`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#%E7%8B%99%E3%81%84-3%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3%E3%83%AD%E3%82%B8%E3%83%83%E3%82%AF%E3%82%92%E7%B4%94%E7%B2%8B%E9%96%A2%E6%95%B0%E3%81%A7%E8%A1%A8%E7%8F%BE%E3%81%99%E3%82%8B%E3%81%93%E3%81%A8%E3%81%AB%E3%82%88%E3%82%8B%E5%8F%AF%E8%AA%AD%E6%80%A7%E3%83%BB%E3%83%86%E3%82%B9%E3%83%88%E5%AE%B9%E6%98%93%E6%80%A7%E3%81%AE%E5%90%91%E4%B8%8A"
            }}>{`狙い 3：ドメインロジックを純粋関数で表現することによる可読性・テスト容易性の向上`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#%E3%81%BE%E3%81%A8%E3%82%81"
        }}>{`まとめ`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#%E3%81%95%E3%81%84%E3%81%94%E3%81%AB"
        }}>{`さいごに`}</a></li>
    </ul>
    {
      /* END doctoc generated TOC please keep comment here to allow auto update */
    }
    <a id="tanstack-query-%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6" />
    <h1>{`Tanstack Query について`}</h1>
    <p>{`Tanstack Query は、Web アプリケーションのサーバ状態の取得、キャッシュ、同期、更新を簡単に行うことができるライブラリです。`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup></p>
    <p>{`類似ライブラリには SWR、Apollo Client、RTK Query 等が挙げられます。`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup></p>
    <p>{`私たちは、機能性・ドキュメントの充実度・コミュニティの将来性を総合的に判断した結果、Tanstack Query(React Query)を採用して React コードベースの再構築を進めています。
導入背景の詳細は、後のセクションで詳しく紹介します。`}</p>
    <a id="tanstack-query-%E3%82%92%E6%B4%BB%E7%94%A8%E3%81%97%E3%81%9F%E3%83%95%E3%83%AD%E3%83%B3%E3%83%88%E3%82%A8%E3%83%B3%E3%83%89%E3%82%A2%E3%83%BC%E3%82%AD%E3%83%86%E3%82%AF%E3%83%81%E3%83%A3" />
    <h1>{`Tanstack Query を活用したフロントエンドアーキテクチャ`}</h1>
    <p>{`それでは本題の Tanstack Query を活用したフロントエンドアーキテクチャについて紹介します。
始めに、アーキテクチャの全体像を示した次の図をご覧ください。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d45894a16dc919c53cc029ba43982c55/47027/architecture.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7C0JJLF//8QAGBAAAgMAAAAAAAAAAAAAAAAAARAAAhH/2gAIAQEAAQUCvC8BX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABkQAAIDAQAAAAAAAAAAAAAAAAABESExUf/aAAgBAQABPyHHLLtbonXCBuKVhB//2gAMAwEAAgADAAAAEKcf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/ECjc/8QAFREBAQAAAAAAAAAAAAAAAAAAEDH/2gAIAQIBAT8Qp//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESFBMXFhkaH/2gAIAQEAAT8QThda5cX6GNGAOQDT3Q/Y2nRlBuo8GuR4lDU//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Tanstack Query を活用したフロントエンドアーキテクチャ",
            "title": "Tanstack Query を活用したフロントエンドアーキテクチャ",
            "src": "/static/d45894a16dc919c53cc029ba43982c55/e5166/architecture.jpg",
            "srcSet": ["/static/d45894a16dc919c53cc029ba43982c55/f93b5/architecture.jpg 300w", "/static/d45894a16dc919c53cc029ba43982c55/b4294/architecture.jpg 600w", "/static/d45894a16dc919c53cc029ba43982c55/e5166/architecture.jpg 1200w", "/static/d45894a16dc919c53cc029ba43982c55/d9c39/architecture.jpg 1800w", "/static/d45894a16dc919c53cc029ba43982c55/df51d/architecture.jpg 2400w", "/static/d45894a16dc919c53cc029ba43982c55/47027/architecture.jpg 2654w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`まず注目して頂きたいポイントは、Backend と View の間にある Resource Operation です。`}</p>
    <p>{`Resource Operation は Tanstack Query を主軸に実装されているレイヤーです。
役割を大きく分類すると次の 2 つが挙げられます。`}</p>
    <ol>
      <li parentName="ol">{`非同期状態管理に関連する実装の集約`}</li>
      <li parentName="ol">{`Backend で扱うデータ(OpenAPI スキーマ)と View で扱うデータ(ViewModel)の相互変換`}</li>
    </ol>
    <p>{`全体像を把握するために、左右のレイヤーにも注目してください。`}</p>
    <p>{`右の Backend は CLINICS では Ruby on Rails で実装された REST API を提供するモノリシックなサーバです。
API で扱うスキーマは OpenAPI を使って定義しています。
OpenAPI スキーマは、`}<a parentName="p" {...{
        "href": "https://github.com/willnet/committee-rails"
      }}>{`committee-rails`}</a>{` を使ったレスポンス検証と、`}<a parentName="p" {...{
        "href": "https://github.com/OpenAPITools/openapi-generator"
      }}>{`openapi-generator`}</a>{` を使った API クライアントコードの自動生成に活用しています。`}</p>
    <p>{`左の View は CLINICS では React で実装されたコンポーネントになります。
View では Backend の OpenAPI スキーマを直接参照することを避け、 ViewModel と呼ばれるフロントエンドで定義したモデル`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup>{`のみを参照する設計としています。`}</p>
    <p>{`Resource Operation は View と Backend の境界レイヤーとして非同期状態管理とデータの相互変換を行うシンプルなレイヤーとなっています。`}</p>
    <p>{`続いて、上記の運用のための実装詳細を紹介します。`}</p>
    <a id="resource-operation-%E3%81%AE%E5%AE%9F%E8%A3%85" />
    <h2>{`Resource Operation の実装`}</h2>
    <a id="%E3%83%87%E3%82%A3%E3%83%AC%E3%82%AF%E3%83%88%E3%83%AA%E6%A7%8B%E6%88%90" />
    <h3>{`ディレクトリ構成`}</h3>
    <p>{`Resource Operation レイヤーでは、リソースごとに非同期状態管理とデータの相互変換の処理をまとめています。`}</p>
    <p>{`ディレクトリツリーで Resource Operation レイヤー全体を表現すると次のようになります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sh"
    }}><pre parentName="div" {...{
        "className": "language-sh"
      }}><code parentName="pre" {...{
          "className": "language-sh"
        }}>{`src/resourceOperations
├── resourceTagName      # 例： todo
│   ├── cache.ts
│   ├── mutations.ts
│   └── queries.ts
└── resourceGroupTagName # 例： systemSettings（リソースに階層がある場合）
    └── resourceTagName  # 例： organization（リソースグループ配下のリソース）
        ├── cache.ts
        ├── mutations.ts
        └── queries.ts`}</code></pre></div>
    <p>{`ディレクトリ名の `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`resourceTagName`}</code>{` と `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`resourceGroupTagName`}</code>{` は OpenAPI スキーマでエンドポイントごとに割り振られている tag を元に命名しています。`}</p>
    <p>{`ディレクトリごとに 次の 3 つのファイルを定義しています。`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ファイル`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`役割`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cache.ts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Query Key の定義、キャッシュ操作のためのカスタムフックの定義`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`queries.ts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`useQuery をラップしたカスタムフックの定義、API Request/Response の整形`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`mutations.ts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`useMutation をラップしたカスタムフックの定義、API Request の整形`}</td>
        </tr>
      </tbody>
    </table>
    <a id="cachets" />
    <h3>{`cache.ts`}</h3>
    <p>{`cache.ts は次のように実装しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}><pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// ① Query Key`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// queries.ts がある場合に必要に応じて宣言`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` todoKeys `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  all`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"todo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`list`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`todoKeys`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`all`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"list"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`paginateList`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`page`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`todoKeys`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`list`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` page `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`detail`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`todoKeys`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`all`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"detail"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ② キャッシュ操作のためのカスタムフック`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// mutations.ts がある場合に必要に応じて宣言`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useTodoCache`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` queryClient `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useQueryClient`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useMemo`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`invalidateList`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` queryClient`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`invalidateQueries`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`todoKeys`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`list`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`invalidateDetail`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
        queryClient`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`invalidateQueries`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`todoKeys`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`detail`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`queryClient`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`① Query Key は、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useQuery`}</code>{` の `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`queryKey`}</code>{` オプションに与える値を定義した定数です。これは後述の queries.ts で利用します。
実装は Tanstack Query メンテナの Dominik さんのブログで紹介されている `}<a parentName="p" {...{
        "href": "https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories"
      }}>{`Query Key factories`}</a>{` パターンを使っています。
ディレクトリ名を `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`all`}</code>{` の値とすることで、大規模なアプリケーションにおいてもキーの衝突を防ぐことが可能です。`}</p>
    <p>{`② キャッシュ操作のためのカスタムフックでは QueryClient を利用したキャッシュ操作をまとめています。これは後述の mutations.ts で利用します。
CLINICS では`}<a parentName="p" {...{
        "href": "https://tanstack.com/query/v4/docs/react/guides/optimistic-updates"
      }}>{`楽観的更新`}</a>{`をしない方針としているため、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`invalidateQueries`}</code>{` を実行する関数群のみを定義しています。`}</p>
    <a id="queriests" />
    <h3>{`queries.ts`}</h3>
    <p>{`queries.ts は次のように実装しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}><pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Todo`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"@/viewModels/todo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` todoApi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`GetTodoDetailRequest`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"@/api/generated"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ③ queryFn`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` query `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  getTodoList`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`async`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Promise`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Todo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` data `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` todoApi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getTodoList`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`todoList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  getTodoDetail`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`async`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`request`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` GetTodoDetailRequest`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Promise`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Todo`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` data `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` todoApi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getTodoDetail`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`request`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`todo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ④ Request Selector`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` request `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  getTodoDetail`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` GetTodoDetailRequest `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// \`enabled: false\` となる条件の引数が与えられた場合例外とすることで、`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// queryFn 内の型の整合性を保つ`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throw`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`InvalidRequestParameterError`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Required parameter id was undefined when calling request.getTodoDetail."`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`③ queryFn は、API へのリクエストを責務とした関数群です。
ここで使用している ApiClient や Request の型は openapi-generator から生成しています。
`}<strong parentName="p">{`queryFn の戻り値の型は、後述の ViewModel で定義した型を明示`}</strong>{`しています。
このようにフロントエンド側でサーバステートの型を別途定義することで、フロントエンドとバックエンドを分業して実装する際に開発しやすくなるメリットがあります。
省略していますが、レスポンスに応じたエラーの `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`throw`}</code>{` もここで行います。`}</p>
    <p>{`④ Request Selector は View から受け取った値をリクエストパラメータへマッピングすることを責務とした関数群です。
useQuery の条件付き実行を制御する `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`enabled`}</code>{` オプションで `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`false`}</code>{` となる条件を例外とすることで、queryFn 内で 型ガードをしなくて良い設計としています。`}</p>
    <p>{`これらを使って View とのインターフェースとなる useQuery ラッパーを定義します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}><pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// ⑤ Base Query`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// API Response を整形せずに返す`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UseTodoDetailQueryProps`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`QueryResult`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  select`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Todo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` QueryResult`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token generic-function"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`useTodoDetailQuery`}</span><span parentName="span" {...{
              "className": "token generic class-name"
            }}><span parentName="span" {...{
                "className": "token operator"
              }}>{`<`}</span>{`QueryResult `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` Todo`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`>`}</span></span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  props`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` UseTodoDetailQueryProps`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QueryResult`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useQuery`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    queryKey`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` todoKeys`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`detail`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`queryFn`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` query`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getTodoDetail`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`request`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getTodoDetail`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    enabled`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    select`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`select`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    useErrorBoundary`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` selectTodoForm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"@/viewModels/todo/todoForm"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ⑥ Selector Query`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// API Response を View で参照したいフォーマットに整形して返す`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useInitialTodoFormQuery`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Base Query に select オプションを与える`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useTodoDetailQuery`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    select`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` selectTodoForm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// selectTodoForm は Todo を TodoForm に変換する ViewModel Selector（後述）`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`useQuery ラッパーは、⑤ Base Query と ⑥ Selector Query に分けて定義しています。
CLINICS では同じデータソースに対して画面ごとに異なるフォーマットで表示することが多くあります。
Selector Query で 任意のフォーマットに整形することで、画面ごとに最適化されたデータの取得をスケーラブルに実現しています。`}<sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup></p>
    <p>{`加えて、この手法では Selector にドメインロジックが凝集されるため、`}<strong parentName="p">{`Selector を重点的にテストすることで品質を担保しやすい`}</strong>{`メリットがあります。`}</p>
    <p>{`Query のエラー制御は `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useErrorBoundary`}</code>{` オプションを使って `}<a parentName="p" {...{
        "href": "https://ja.reactjs.org/docs/error-boundaries.html"
      }}>{`Error Boundary`}</a>{` を表示する方針としています。`}</p>
    <a id="mutationsts" />
    <h3>{`mutations.ts`}</h3>
    <p>{`mutations.ts は次のように実装しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}><pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TodoForm`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"@/viewModels/todo/todoForm"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` todoApi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`PostTodoRequest`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"@/api/generated"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ⑦ mutationFn`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` mutation `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`createTodo`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`request`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` PostTodoRequest`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` todoApi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`postTodo`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`request`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ⑧ Request Selector`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ViewModel から API Request へ変換する`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` request `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  createTodo`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`todoForm`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` TodoForm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` PostTodoRequest `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      PostTodoRequest`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        todo`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          title`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` todoForm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`title`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          description`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` todoForm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`description`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          status`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` todoForm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          favorite`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` todoForm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`favorite `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"true"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ⑨ Custom Mutation`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useCreateTodoMutation`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` invalidateList `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useTodoCache`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useMutation`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`mutationFn`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`todoForm`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` TodoForm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` mutation`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`createTodo`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`request`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`createTodo`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`todoForm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`onSuccess`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`invalidateList`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`基本的な構成は queries.ts と同じです。`}</p>
    <p>{`mutations.ts でも queries.ts と同様に ⑧ Request Selector を定義します。
Request Selector には、データ整形を扱うためドメインロジックが集まりやすいです。
そのため、入出力、境界値、例外のテストを積極的に書いて品質の担保に繋げています。`}</p>
    <p>{`Mutation のエラーは画面ごとに UI でフィードバックするため、コンポーネント側で制御しています。`}</p>
    <p>{`Resource Operation レイヤーに関する実装の紹介は以上です。`}</p>
    <a id="viewmodel-%E3%81%AE%E5%AE%9F%E8%A3%85" />
    <h2>{`ViewModel の実装`}</h2>
    <p>{`ViewModel とは、View(React Component) で扱うデータのスキーマと型です。`}</p>
    <p>{`Resource Operation の実装では、API Response を ViewModel に整形して View に提供することを紹介しました。`}</p>
    <p>{`全体の理解を深めるため、ViewModel の実装も紹介します。`}</p>
    <a id="%E3%83%87%E3%82%A3%E3%83%AC%E3%82%AF%E3%83%88%E3%83%AA%E6%A7%8B%E6%88%90-1" />
    <h3>{`ディレクトリ構成`}</h3>
    <p>{`ViewModel は関心を分離するため Resource Operation とは別のディレクトリに定義しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sh"
    }}><pre parentName="div" {...{
        "className": "language-sh"
      }}><code parentName="pre" {...{
          "className": "language-sh"
        }}>{`src/viewModels
└── todo
    ├── todo.ts                # サーバステート
    ├── todoForm.test.ts       # フロントエンドに閉じたスキーマのテスト
    ├── todoForm.ts            # フロントエンドに閉じたスキーマ
    └── todoSearchCondition.ts # フロントエンドに閉じた型`}</code></pre></div>
    <p>{`ViewModel で定義するスキーマ・型は大きく分けて 3 つに分類されます。`}</p>
    <ul>
      <li parentName="ul">{`サーバステート`}</li>
      <li parentName="ul">{`フロントエンドに閉じたスキーマ`}</li>
      <li parentName="ul">{`フロントエンドに閉じた型`}</li>
    </ul>
    <a id="%E3%82%B5%E3%83%BC%E3%83%90%E3%82%B9%E3%83%86%E3%83%BC%E3%83%88" />
    <h3>{`サーバステート`}</h3>
    <p>{`サーバステートは、 API Response として期待するデータのスキーマ及び型です。
前述の queries.ts 内の ③ queryFn で使用します。`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/colinhacks/zod"
      }}>{`zod`}</a>{` を使って次のように実装しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}><pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// src/viewModels/todo/todo.ts`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// enum は View で <option value={todoStatus.enum.ready} /> のように使用する`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` todoStatus `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` z`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`enum`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ready"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"doing"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"done"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ⑩ サーバステートのスキーマ`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 開発中のみ ③ queryFn 内で API Response を parse することで、スキーマの不整合を検出するための補助輪として使用する`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` todo `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` z`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`object`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` z`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  title`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` z`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  description`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` z`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`nullable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  status`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` todoStatus`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  favorite`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` z`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`boolean`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ⑪ サーバステートの型`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ③ queryFn の戻り値の型として使用する`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Todo`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` z`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`infer`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`typeof`}</span>{` todo`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`⑩ サーバステートのスキーマは、⑪ サーバステートの型の生成と開発時のスキーマ検証に使用しています。`}</p>
    <p>{`開発時のみ API Response を検証することで、⑩ サーバステートのスキーマと Open API スキーマの整合性を確認しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}><pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// src/resourceOperations/todo/queries.ts`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` todo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Todo`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"@/viewModels/todo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` query `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  getTodoList`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`async`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Promise`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Todo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` data `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` todoApi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getTodoList`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 開発時、APIとの結合タイミングで検証してフロントエンドとバックエンドでスキーマの齟齬がないことを確認する`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 検証が済んだら parse 処理を外す`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`todoList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` todo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`parse`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`例外として、`}<strong parentName="p">{`外部サービスから取得したデータに関しては常にサーバステートのスキーマを使って検証`}</strong>{`しています。
例えば、外部サービスの仕様として長さが 1 以上の配列が返ってくると決まっていて、フロントエンド側もその仕様に基づいた処理を実装している場合、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`z.array().min(1)`}</code>{` のスキーマで常に検証します。
ネットワークに近い箇所で不正なデータを検出することで、例外発生時の調査を容易にするメリットがあると考えています。`}</p>
    <a id="%E3%83%95%E3%83%AD%E3%83%B3%E3%83%88%E3%82%A8%E3%83%B3%E3%83%89%E3%81%AB%E9%96%89%E3%81%98%E3%81%9F%E3%82%B9%E3%82%AD%E3%83%BC%E3%83%9E" />
    <h3>{`フロントエンドに閉じたスキーマ`}</h3>
    <p>{`フロントエンドに閉じたスキーマは、そのほとんどがフォームのバリデーションスキーマです。
こちらも zod を使って定義しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}><pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// src/viewModels/todo/todoForm.ts`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Todo`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` todo `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"./todo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// フロントエンドに閉じたスキーマ`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// フォームのスキーマは react-hook-form と連携して使用する（省略）`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` todoForm `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` z`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`object`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  title`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` z
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"入力してください"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`200`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"200字以内で入力してください"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  description`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` z`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`500`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"500字以内で入力してください"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  status`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` todo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`shape`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  favorite`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` z`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`union`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`z`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`literal`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"true"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` z`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`literal`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TodoForm`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` z`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`infer`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`typeof`}</span>{` todoForm`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ⑫ ViewModel Selector`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// サーバステートからフロントエンドに閉じたスキーマへ変換する`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 前述の queries.ts 内 ⑥ Selector Query で使用する`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`selectTodoForm`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`todo`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Todo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` TodoForm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    title`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` todo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`title`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    description`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` todo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`description `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`??`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    status`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` todo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    favorite`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` todo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`favorite `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"true"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`フロントエンドに閉じたスキーマは、必ずサーバステートを元に生成する運用としています。
そのために、フロントエンドに閉じたスキーマを宣言した直下に、サーバステートからフロントエンドに閉じたスキーマへ変換する ⑫ ViewModel Selector を定義します。
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`null`}</code>{` の 空文字への変換や時間データのフォーマットのような、 サーバステート と View で使う値の差分吸収はこのセレクタ内で行います。`}</p>
    <p>{`このように、`}<strong parentName="p">{`ViewModel レイヤーでは他のレイヤーと依存しないようにドメインロジックを表現`}</strong>{`しています。
ドメインロジックを Tanstack Query に依存しないことで、今後技術基盤を刷新する場合でも影響を最小限に留めることを狙いとしています。`}</p>
    <a id="%E3%83%95%E3%83%AD%E3%83%B3%E3%83%88%E3%82%A8%E3%83%B3%E3%83%89%E3%81%AB%E9%96%89%E3%81%98%E3%81%9F%E5%9E%8B" />
    <h3>{`フロントエンドに閉じた型`}</h3>
    <p>{`依存関係を整えるため、Resource Operation と View から参照するフロントエンドに閉じた型は viewModels 配下に宣言しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}><pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// src/viewModels/todo/todoSearchParams.ts`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TodoSearchCondition`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  sort`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"created_at_asc"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"created_at_desc"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`ViewModel に関する実装の紹介は以上です。`}</p>
    <a id="tanstack-query-%E5%B0%8E%E5%85%A5%E3%81%AE%E8%83%8C%E6%99%AF%E3%81%A8%E3%82%A2%E3%83%BC%E3%82%AD%E3%83%86%E3%82%AF%E3%83%81%E3%83%A3%E3%81%AE%E7%8B%99%E3%81%84" />
    <h1>{`Tanstack Query 導入の背景とアーキテクチャの狙い`}</h1>
    <a id="%E8%83%8C%E6%99%AF%E3%82%B3%E3%83%BC%E3%83%89%E5%93%81%E8%B3%AA%E3%81%AE%E8%AA%B2%E9%A1%8C" />
    <h2>{`背景：コード品質の課題`}</h2>
    <p>{`Tanstack Query を導入する以前の CLINICS の非同期処理周辺のコードベースではいくつかの課題がありました。`}</p>
    <p><strong parentName="p">{`開発体験の課題`}</strong></p>
    <p>{`非同期処理のためのミニマムな基盤フックを独自に実装していた`}<sup parentName="p" {...{
        "id": "fnref-5"
      }}><a parentName="sup" {...{
          "href": "#fn-5",
          "className": "footnote-ref"
        }}>{`5`}</a></sup>{`ことにより、開発体験の観点で次のような課題がありました。`}</p>
    <ul>
      <li parentName="ul">{`新しい要件（ポーリング・無限読み込み等）が発生した際に、最初に担当する開発者が都度機能拡張する必要がある`}</li>
      <li parentName="ul">{`テストが実装されていなかったため変更時に品質確認の負担が大きい`}</li>
    </ul>
    <p><strong parentName="p">{`学習容易性の課題`}</strong></p>
    <p>{`上述の基盤フックにドキュメントがなかったため、学習容易性の観点で次のような課題がありました。`}</p>
    <ul>
      <li parentName="ul">{`基盤フックにドキュメントがなく、新規メンバーの学習コストが高い`}</li>
      <li parentName="ul">{`様々な実装手法が混在することで、実装時に迷いが生じている`}</li>
    </ul>
    <p><strong parentName="p">{`可読性の課題`}</strong></p>
    <p>{`CLINICS は開発開始から約 7 年以上が経過しています。
その中でもフロントエンドは技術トレンドの変化が早いため、様々なライブラリやパターンを使って実装されています。
特に近年から漸進的に導入した React を使った実装については、明確な設計が確立されていませんでした。`}</p>
    <p>{`これらの背景からチームで安定したアウトプットを出すことが困難で、可読性の観点で次のような課題がありました。`}</p>
    <ul>
      <li parentName="ul">{`画面によってフックや関数の粒度、定義場所が違うことでコードリーディングの負荷が高い`}</li>
      <li parentName="ul">{`コードレビュー時のレビュワーの負担が大きい`}</li>
    </ul>
    <p><strong parentName="p">{`テスト容易性の課題`}</strong></p>
    <p>{`多くのドメインロジックがコンポーネントやカスタムフック内に混在していることで、テスト容易性の観点で次のような課題がありました。`}</p>
    <ul>
      <li parentName="ul">{`テストが実装しづらい`}</li>
      <li parentName="ul">{`テストカバレッジが低い`}</li>
    </ul>
    <p>{`CLINICS はオンライン診療・電子カルテ等の医療機関業務を支える機能を提供する SaaS プラットフォームです。
今後長きに渡って多くの医療機関の方々に CLINICS を利用して頂くためには、`}<strong parentName="p">{`コードを読みやすく、変更しやすく、維持しやすい状態に保ち続けること`}</strong>{`が重要です。`}</p>
    <p>{`前の章で紹介したアーキテクチャは`}<strong parentName="p">{`持続可能な開発を目指して`}</strong>{`設計しました。
具体的には、`}<strong parentName="p">{`開発体験・学習容易性・可読性・テスト容易性を向上することを狙い`}</strong>{`としています。`}</p>
    <a id="%E7%8B%99%E3%81%84-1tanstack-query-%E3%81%AE%E5%B0%8E%E5%85%A5%E3%81%AB%E3%82%88%E3%82%8B%E9%96%8B%E7%99%BA%E4%BD%93%E9%A8%93%E3%81%AE%E5%90%91%E4%B8%8A" />
    <h2>{`狙い 1：Tanstack Query の導入による開発体験の向上`}</h2>
    <p>{`背景で説明したとおり、Tanstack Query 導入以前は独自実装したフックを使って非同期処理を実装していました。`}</p>
    <p>{`Tanstack Query を導入したきっかけは、チーム内での雑談の中で、独自実装のフックが使いづらいという声が挙がったことです。
そこで、独自実装のフック自体の質を高めるか、質の高いライブラリを導入するかを議論した結果、次の理由でライブラリを導入する決定をしました。`}</p>
    <ul>
      <li parentName="ul">{`極力自分たちでコードを書かずに非同期処理・状態管理の実装を実現したい`}</li>
      <li parentName="ul">{`実装に困ったときにドキュメントを読めば解決する環境にしたい`}</li>
    </ul>
    <p>{`CLINICS では技術的な背景`}<sup parentName="p" {...{
        "id": "fnref-6"
      }}><a parentName="sup" {...{
          "href": "#fn-6",
          "className": "footnote-ref"
        }}>{`6`}</a></sup>{`から Tanstack Query と SWR が候補に上がりましたが、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`select`}</code>{` オプションや `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`invalidateQueries`}</code>{` の `}<a parentName="p" {...{
        "href": "https://tanstack.com/query/v4/docs/react/guides/query-invalidation#query-matching-with-invalidatequeries"
      }}>{`Partial Query Matching`}</a>{` 等の機能性と、ドキュメントの充実度合いの観点から Tanstack Query を採用しました。`}</p>
    <p>{`Tanstack Query を採用したことで、`}<strong parentName="p">{`非同期処理のためのコードの記述量が大幅に削減`}</strong>{`されたほか、データの特性に応じて Query ごとにキャッシュの時間を調整することが可能となり開発体験が大幅に向上しました。`}</p>
    <a id="%E7%8B%99%E3%81%84-2%E3%82%B7%E3%83%B3%E3%83%97%E3%83%AB%E3%81%AA%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC%E5%88%86%E5%89%B2%E3%81%AB%E3%82%88%E3%82%8B%E5%AD%A6%E7%BF%92%E5%AE%B9%E6%98%93%E6%80%A7%E3%81%AE%E5%90%91%E4%B8%8A" />
    <h2>{`狙い 2：シンプルなレイヤー分割による学習容易性の向上`}</h2>
    <p>{`CLINICS では、事業の拡大にともないコードベースに関わるエンジニアが増え続けています。
実装の進め方はプロジェクトによって最適な形式を選択しています。`}</p>
    <ul>
      <li parentName="ul">{`バックエンドからフロントエンドまで一気通貫で実装`}</li>
      <li parentName="ul">{`技術領域に分けて分業`}</li>
    </ul>
    <p>{`このように多くのエンジニアが様々な形で関わる環境では、コードベースの学習容易性を高め、実装からコードレビューの完了までをスムーズに行えることが重要です。`}</p>
    <p>{`前の章で紹介したアーキテクチャは、`}<strong parentName="p">{`レイヤー分割をシンプルにすることでコードベースに慣れるまでの時間を最小限にする`}</strong>{`ことを意識しています。`}</p>
    <p>{`加えて、`}<strong parentName="p">{`実装パターンを定形化することで、コードベースに馴染みがなくても迷いなく実装できる`}</strong>{`ほか、関数の粒度が統一されることで、コードレビューの負荷軽減にも繋がっています。`}</p>
    <p>{`レイヤー分割の粒度や実装パターンについては、次の記事を参考にさせて頂きました。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://zenn.dev/yoshiko/articles/91a3dd575f99a2"
        }}>{`フロントエンドアーキテクチャの話: Resource Set の紹介`}</a></li>
    </ul>
    <p>{`ほかにも CLINICS では学習容易性の向上の取り組みとして、`}<strong parentName="p">{`新しいライブラリやアーキテクチャを導入した際は勉強会を開催`}</strong>{`してライブラリの基本的な使い方や頻出の実装パターンに関する知見を共有しています。`}</p>
    <a id="%E7%8B%99%E3%81%84-3%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3%E3%83%AD%E3%82%B8%E3%83%83%E3%82%AF%E3%82%92%E7%B4%94%E7%B2%8B%E9%96%A2%E6%95%B0%E3%81%A7%E8%A1%A8%E7%8F%BE%E3%81%99%E3%82%8B%E3%81%93%E3%81%A8%E3%81%AB%E3%82%88%E3%82%8B%E5%8F%AF%E8%AA%AD%E6%80%A7%E3%83%BB%E3%83%86%E3%82%B9%E3%83%88%E5%AE%B9%E6%98%93%E6%80%A7%E3%81%AE%E5%90%91%E4%B8%8A" />
    <h2>{`狙い 3：ドメインロジックを純粋関数で表現することによる可読性・テスト容易性の向上`}</h2>
    <p>{`アーキテクチャを刷新する以前は、多くのドメインロジックがコンポーネントやカスタムフック内に混在していることで、可読性やテスト容易性に支障をきたしていました。`}</p>
    <p>{`CLINICS のフロントエンドには、医療システムに関する複雑なドメインロジックが多いため、`}<strong parentName="p">{`シンプルでテストしやすいコードベース`}</strong>{`を作っていくことがとりわけ重要だと考えています。`}</p>
    <p>{`この課題は、ドメインロジックが集まる傾向にある queries.ts、 mutations.ts の Request Selector や ViewModel Selector の実装を定型化し、純粋関数で表現することにより解決しました。`}</p>
    <a id="%E3%81%BE%E3%81%A8%E3%82%81" />
    <h1>{`まとめ`}</h1>
    <p>{`Tanstack Query を使ったフロントエンドアーキテクチャの実例を紹介しました。`}</p>
    <ul>
      <li parentName="ul">{`Resource Operation レイヤーに Tanstack Query の実装を定型化して集約しています。`}</li>
      <li parentName="ul">{`レイヤー分割をシンプルにし、実装を定型化することで、開発組織のスケールに対応しています。`}</li>
      <li parentName="ul">{`useQuery の `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`select`}</code>{` オプションを使い、スケーラブルにデータ変換処理を記述しています。`}</li>
      <li parentName="ul">{`データ変換処理にはドメインロジックが集まりやすいため、なるべく小さい粒度の純粋関数で表現することで、可読性・テスト容易性の向上を狙っています。`}</li>
    </ul>
    <p>{`この記事の内容が Tanstack Query の導入を考えている方の参考になれば幸いです。`}</p>
    <a id="%E3%81%95%E3%81%84%E3%81%94%E3%81%AB" />
    <h1>{`さいごに`}</h1>
    <p>{`CLINICS では、機能開発と並行してフロントエンド基盤を改善する取り組みも実施しています。`}</p>
    <ul>
      <li parentName="ul">{`Redux から Tanstack Query への移行`}</li>
      <li parentName="ul">{`UI ライブラリの `}<a parentName="li" {...{
          "href": "https://mithril-ja.js.org/"
        }}>{`Mithril`}</a>{` から React への移行`}<sup parentName="li" {...{
          "id": "fnref-7"
        }}><a parentName="sup" {...{
            "href": "#fn-7",
            "className": "footnote-ref"
          }}>{`7`}</a></sup></li>
      <li parentName="ul">{`デザインシステムの構築とプロダクトへの反映`}</li>
    </ul>
    <p>{`このような取り組みに興味がある方は次のリンクから是非ご連絡ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>
          <small><a href="https://tanstack.com/query/latest/docs/react/overview">Overview | TanStack Query Docs</a></small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-1",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>
          <small><a href="https://tanstack.com/query/v4/docs/react/comparison">Comparison | React Query vs SWR vs Apollo vs RTK Query vs React Router | TanStack Query Docs</a></small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-2",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>
          <small>この記事で紹介している ViewModel は View レイヤー専用のモデルを表す概念です。 MVVM アーキテクチャの ViewModel とは異なります。</small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-3",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}>
          <small>Tanstack Query におけるデータ変換手法の詳細は <a href="https://tkdodo.eu/blog/react-query-data-transformations">React Query Data Transformations | TkDodo's blog</a> で紹介されています。</small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-4",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
        <li parentName="ol" {...{
          "id": "fn-5"
        }}>
          <small>Tanstack Query 導入以前の非同期処理は <a href="https://usehooks.com/useAsync/">useAsync React Hook - useHooks</a> をカスタマイズしたフックを使って実装していました。</small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-5",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
        <li parentName="ol" {...{
          "id": "fn-6"
        }}>
          <small>CLINICS では 一部の実装箇所で Redux を使用していますが、 <a href="https://redux-toolkit.js.org/rtk-query/overview">RTK Query</a> は今回採用するライブラリの候補から除外しました。これは、現在使用している Redux のバージョンが低く、レガシーな周辺ライブラリも複数使用している背景で <a href="https://redux-toolkit.js.org/">Redux Toolkit</a> への移行に相当な工数を必要とするためです。</small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-6",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
        <li parentName="ol" {...{
          "id": "fn-7"
        }}>
          <small>2023 年 3 月現在、 CLINICS のフロントエンドの 約 50% は、 Mithril と Redux で構成されています。開発体験の向上のため、 React への完全移行を目指して日々改善を続けています。</small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-7",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      